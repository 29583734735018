<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Contatti</li>
            </ul>
            <h2>Contattaci</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div id="map">
    <iframe src="https://maps.google.com/maps?q=Via%20Michelangelo%20Diana,%202,%2081036%20San%20Cipriano%20d'Aversa%20CE,%20Italia%20Italia&t=k&z=19&ie=UTF8&iwloc=&output=embed"></iframe>
</div>

<div class="contact-area ptb-100">
    <div class="container">
        <div class="row mb-5">
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">Chi siamo</span>
                    <h2>Il Migliore Ente di Formazione Accreditato e Certificato</h2>
                    <p>
                        a Simple Work è una rete capillare, di aziende, consulenti e professionisti distribuita sull’intero territorio nazionale per la promozione di prodotti e servizi ad altissimo valore aggiunto destinati ad imprese, professionisti e privati. Le nuove esigenze dettate dal mercato impongono rapide strategie di proattività, capacità di innovazione e strumenti sempre più avanzati di produttività e monitoraggio.
                    </p>
                    <p>
                        In un simile contesto risulta indispensabile per gli operatori affidarsi ad un team di consulenza integrata costantemente in aggiornamento e sempre attento a nuove opportunità, in grado di fornire soluzioni efficaci in tempi sempre più tempestivi.
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">dalla formazione alla professione</span>
                    <h2>Cosa abbiamo di Speciale?</h2>
                   <p>
                        Simple Work c’è! C’è con i professionisti e con le persone che compongono uno staff altamente specializzato in costante aggiornamento e capace di selezionare e scegliere, nel marasma di informazioni e di opportunità, quelle più adeguate alle esigenze dei propri partner e dei propri clienti.
                    </p>
                    <p>
                    L’approccio – di tipo simbiotico – permette di analizzare, costruire insieme al cliente il modello di gestione strategica del suo business, di pianificarne la crescita aziendale e la valutazione dell’impatto sul funzionamento organizzativo, affiancando professionisti e personale in un percorso di fisiologica evoluzione.
                   </p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">Informazioni</span>
                    <h2>Mettiti in contatto</h2>
                    <p>Ci piace ascoltare i lettori. Apprezziamo davvero che tu abbia dedicato del tempo per metterci in contatto.</p>
                    <ul>
                        <li>
                            <div class="icon">
                                <i class='bx bx-map'></i>
                            </div>
                            <h3>Dove siamo</h3>
                            <p>Via Michelangelo Diana, 2<br>
                                81036 San Cipriano d’ Aversa (CE)</p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-phone-call'></i>
                            </div>
                            <h3>Contatti</h3>
                            <p>Mobile: <a href="tel:+390819131396">(+39) 081-9131396</a></p>
                            <p>e-mail: <a href="mailto:simpleworkformazione@virglio.it">simpleworkformazione@virglio.it</a></p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-time-five'></i>
                            </div>
                            <h3>Siamo disponibili</h3>
                            <p>Lunedì - Venerdì: 09:00 - 18:00</p>
                            <p>Sabato: 09:00 - 13:00</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-form" *ngFor="let Image of bgImage;" style="background-image: url({{Image.img}});">
                    <h2>Come possiamo aiutarti?</h2>
                    <p>L'indirizzo email non verrà pubblicato. i campi richiesti sono contrassegnati dal simbolo*</p>
                    <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
                        <div class="row">
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name" placeholder="Nome e Cognome">
                                    <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                        <div *ngIf="name.errors.required">Nome e Cognome richiesto.</div>
                                        <div *ngIf="name.errors.minlength">Numero minimo {{ name.errors.minlength.requiredLength }} di caratteri.</div>
                                        <div *ngIf="name.errors.maxlength">Numero massimo di 50 caratteri.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input required ngModel name="email" type="text" #email="ngModel" class="form-control" id="email" placeholder="la tua e-mail">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input required ngModel name="number" type="text" #number="ngModel" class="form-control" id="number" placeholder="cellulare">
                                    <div class="alert alert-danger" *ngIf="number.touched && !number.valid">un contatto telefonico è obbligatorio</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea required ngModel #message="ngModel" name="message" id="message" cols="30" rows="5" class="form-control" placeholder="scrivi il tuo messaggio..."></textarea>
                                    <div class="alert alert-danger" *ngIf="message.touched && !name.valid">il messaggio è obbligatorio</div>
                                </div>
                            </div>
                            <h2 class="mt-3">Quale corso sei interessato?</h2>
                            <p>seleziona il corso interessato alla quale vuoi avere informazioni*</p>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <select name="sceltaCorso" ngModel id="sceltaCorso" #sceltaCorso="ngModel" style="display: block;
                                    width: 100%;
                                    border: none;
                                    color: var(--blackColor);
                                    background-color: #F3F6F9;
                                    height: 50px;
                                    padding-left: 15px;
                                    border-radius: 5px;
                                    font-size: var(--fontSize);
                                    font-weight: 600">
                                        <option value="{{elencoCorsiRow['corso']['titolo']}}" *ngFor="let elencoCorsiRow of elencoCorsi">{{elencoCorsiRow['corso']['titolo']}}</option>
                                      </select>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 mt-3">
                                <button type="submit" class="default-btn" [class.disabled]="!contactForm.valid">Invia Richiesta<span></span></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
