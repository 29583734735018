import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CrudService } from 'src/app/components/services/crud.service';

@Component({
    selector: 'app-elearning-banner',
    templateUrl: './elearning-banner.component.html',
    styleUrls: ['./elearning-banner.component.scss']
})
export class ElearningBannerComponent implements OnInit {
    public idCorsista : string="";
    public urlImage = environment.urlImageCorso;
    public elencoCorsiInHome : any=[];

    constructor(
        private crudService: CrudService
    ) { }

    ngOnInit(): void {
        let detailUser=JSON.parse(localStorage.getItem('user_id'));
        if(detailUser) {
            this.idCorsista=detailUser['id_corsista']
        }

        this.loadCorsiInHome();
    }

    bgImage = [
        {
            img: 'assets/img/gray-bg.jpg'
        }
    ]

    loadCorsiInHome() {
        this.crudService.api_get_ElencoCorsiInHome(this.idCorsista).subscribe(productData => {
            this.elencoCorsiInHome=productData;
            console.log(productData);
        },(err)=>{
            console.log(err);
            }, ()=>{
        }
    );
    }

}
